import * as actionTypes from './actions';
import config from './../config';
import isEmpty from "is-empty";
import thunk from "redux-thunk";
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';


const initialState = {
    isOpen: [], //for active default menu
    isTrigger: [], //for active default menu, set blank for horizontal
    ...config,
    isFullScreen: false, // static can't change
    isAuthenticated: false,
    user: {},
    loading: false,
};


const reducer = (state = initialState, action) => {
    let trigger = [];
    let open = [];

    switch (action.type) {
        case actionTypes.COLLAPSE_MENU:
            return {
                ...state,
                collapseMenu: !state.collapseMenu
            };
        case actionTypes.COLLAPSE_TOGGLE:
            if (action.menu.type === 'sub') {
                open = state.isOpen;
                trigger = state.isTrigger;

                const triggerIndex = trigger.indexOf(action.menu.id);
                if (triggerIndex > -1) {
                    open = open.filter(item => item !== action.menu.id);
                    trigger = trigger.filter(item => item !== action.menu.id);
                }

                if (triggerIndex === -1) {
                    open = [...open, action.menu.id];
                    trigger = [...trigger, action.menu.id];
                }
            } else {
                open = state.isOpen;
                const triggerIndex = (state.isTrigger).indexOf(action.menu.id);
                trigger = (triggerIndex === -1) ? [action.menu.id] : [];
                open = (triggerIndex === -1) ? [action.menu.id] : [];
            }

            return {
                ...state,
                isOpen: open,
                isTrigger: trigger
            };
        case actionTypes.NAV_CONTENT_LEAVE:
            return {
                ...state,
                isOpen: open,
                isTrigger: trigger,
            };
        case actionTypes.NAV_COLLAPSE_LEAVE:
            if (action.menu.type === 'sub') {
                open = state.isOpen;
                trigger = state.isTrigger;

                const triggerIndex = trigger.indexOf(action.menu.id);
                if (triggerIndex > -1) {
                    open = open.filter(item => item !== action.menu.id);
                    trigger = trigger.filter(item => item !== action.menu.id);
                }
                return {
                    ...state,
                    isOpen: open,
                    isTrigger: trigger,
                };
            }
            return {...state};
        case actionTypes.FULL_SCREEN :
            return {
                ...state,
                isFullScreen: !state.isFullScreen
            };
        case actionTypes.FULL_SCREEN_EXIT:
            return {
                ...state,
                isFullScreen: false
            };
        case actionTypes.CHANGE_LAYOUT:
            return {
                ...state,
                layout: action.layout
            };

            case actionTypes.SET_CURRENT_USER:
            return {
                ...state,
                // isAuthenticated: !isEmpty(action.payload),
                user: action.payload
            };
        case actionTypes.USER_ADD:
            return {
                // isAuthenticated: !isEmpty(action.payload),
                user: action.payload
            };
        case actionTypes.USER_UPDATE:
            return {
                // isAuthenticated: !isEmpty(action.payload),
                user: action.payload,
            };
        case actionTypes.USER_LOADING:
            return {
                ...state,
                loading: true
            };
        case actionTypes.ABOUT_ADD:
                return {
                     user: action.payload
                };
        case actionTypes.ABOUT_UPDATE:
                return {
                      user: action.payload,
                };
        case actionTypes.SOCIALLINK_ADD:
        return {
                user: action.payload
        };
        case actionTypes.SOCIALLINK_UPDATE:
        return {
                user: action.payload,
        };
        case actionTypes.NEWS_ADD:
            return {
                    user: action.payload
            };
        case actionTypes.NEWS_UPDATE:
        return {
                user: action.payload,
        };
        case actionTypes.ADDRESS_ADD:
        return {
                user: action.payload
        };
        case actionTypes.ADDRESS_UPDATE:
            return {
                    user: action.payload,
            };

        case actionTypes.CONTACT_ADD:
        return {
                user: action.payload
        };
        case actionTypes.CONTACT_UPDATE:
        return {
                user: action.payload,
        };
        case actionTypes.GLOBAL_ADD:
            return {
                    user: action.payload
            };
        case actionTypes.GLOBAL_UPDATE:
            return {
                    user: action.payload,
            };
        case actionTypes.NFT_ADD:
            return {
                    user: action.payload
            };
        case actionTypes.NFT_UPDATE:
            return {
                    user: action.payload,
            };
        case actionTypes.SCENATIOS_ADD:
            return {
                    user: action.payload
            };
        case actionTypes.SCENATIOS_UPDATE:
            return {
                    user: action.payload,
            };
        case actionTypes.BUSINESS_ADD:
            return {
                    user: action.payload
            };
        case actionTypes.BUSINESS_UPDATE:
            return {
                    user: action.payload,
            };
        case actionTypes.PRODUCT_ADD:
            return {
                    user: action.payload
            };
        case actionTypes.PRODUCT_UPDATE:
            return {
                    user: action.payload,
            };
        case actionTypes.RD_ADD:
            return {
                    user: action.payload
            };
        case actionTypes.RD_UPDATE:
            return {
                    user: action.payload,
            };
        case actionTypes.WASH_ADD:
            return {
                    user: action.payload
            };
        case actionTypes.WASH_UPDATE:
            return {
                    user: action.payload,
            };

        case actionTypes.INTER_ADD:
            return {
                    user: action.payload
            };
        case actionTypes.INTER_UPDATE:
            return {
                    user: action.payload,
            };

        case actionTypes.MANU_ADD:
            return {
                    user: action.payload
            };
        case actionTypes.MANU_UPDATE:
            return {
                    user: action.payload,
            };
        case actionTypes.VERTICAL_ADD:
            return {
                    user: action.payload
            };
        case actionTypes.VERTICAL_UPDATE:
            return {
                    user: action.payload,
            };

        case actionTypes.ETHICAL_UPDATE:
        return {
                user: action.payload,
        };

        case actionTypes.WORLD_UPDATE:
            return {
                    user: action.payload,
            };
        case actionTypes.HOME_UPDATE:
            return {
                    user: action.payload,
            };

        case actionTypes.HOMENEWS_UPDATE:
            return {
                    user: action.payload,
            };
        case actionTypes.OURBUSINESS_ADD:
                return {
                        user: action.payload
                };
        case actionTypes.OURBUSINESS_UPDATE:
                return {
                        user: action.payload,
                };
        default:
            return state;
    }
};

const errorState = {};

 const errorFunction = (state = errorState, action) =>{
    switch (action.type) {
        case actionTypes.GET_ERRORS:
            return action.payload;
        default:
            return state;
    }
}

const store = createStore(
    combineReducers({
        errorFunction   :   errorFunction,
        reducer         :   reducer
    }),applyMiddleware(thunk),
        
);
export default store;